export class OldLabInfo {
  id?: string;
  labDate: string;
  noLabResult: boolean;
  isOrderPlaced: boolean;
  where: string;
  whenDate: string;
  notes: string;
  type: string;
  documentId: string;
  encounterId: string;
  verifiedBy: string;
  verifiedAt: string;
  labResults: {
    coumadin?: boolean;
    inr?: boolean;
    hemoglobin?: number | "";
    potassium?: number | "";
    creatinine?: number | "";
    calcium?: number | "";
    anGap?: number | "";
    bun?: number | "";
    carbonDioxide?: number | "";
    hgb?: number | "";
    hct?: number | "";
    sodium?: number | "";
    glucose?: number | "";
    chloride?: number | "";
    eGFR?: number | "";
    maxContrast?: number | "";
  };
  constructor(encounterId: string, labType: LabType) {
    this.encounterId = encounterId;
    this.type = labType;
    this.labResults = {};
  }
}

export enum LabType {
  FullMetabolic = "Full Metabolic",
  Ultrasound = "Ultrasound",
  ABI = "ABI",
}
