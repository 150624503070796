export class Coverage {
  id?: string;
  patientId?: string;
  procedureId?: string;
  payerName?: string;
  planName?: string;
  payerPlans?: PayerPlan;
  memberId?: string;
  policyHolderName?: string;
  policyNumber?: string;
  policyType?: string[] | string;
  groupNumber?: string;
  effectiveFrom?: string;
  effectiveTo?: string;
  subscriberDob?: string;
  coPay?: string;
  remainingDeductible?: string;
  coInsurance?: string;
  insuranceType?: InsuranceType;
  insuranceStatus?: string;
  subscriberRelationship?: string;
  requiresAuth?: boolean;
  eligibility?: boolean;
  insuranceId?: string;
  subscriber?: InsuranceSubscriber;
  comments?: string;
  subscriberName?: string;
  fileKey?: string;
  fileLink?: string;
  updatedBy?: string;
  updatedAt?: string;

  insuranceFileData?: { base64String: string; contentType: string };
  insuranceFileName?: string;
  constructor(
    patient: string,
    procedureId: string,
    insuranceType: InsuranceType
  ) {
    this.patientId = patient;
    this.insuranceType = insuranceType;
    this.procedureId = procedureId;
    this.policyType = [];
    this.policyNumber="";
    this.groupNumber = "";
  }
}

export interface PayerPlan {
  address: string;
  city: string;
  contractExpirationDate: string;
  contractId: string;
  contractsPlans: string;
  createAt: string;
  createdBy: string;
  deletedAt: string;
  expiration_date: string;
  id: string;
  isInNetwork: boolean;
  organizationId: string;
  payerId: string;
  payerName: string;
  payerPortalId: string;
  payerStatus: string;
  payerWebsite: string;
  phone: string;
  plan: string;
  planId: string;
  planName: string;
  planStatus: string;
  planType: string;
  policyType: string;
  priorAuthRequired: boolean;
  referralRequired: boolean;
  state: string;
  updatedAt: string;
  updatedBy: string;
  zipCode: string;
  payer: Payer;
}

export interface Payer {
  id: string;
  payerCode: string;
  payerName: string;
  eligibility: string;
  type: string;
  claimStatus: string;
}

export interface VerifyEligibilityPayload {
  payerCode: string;
  payerName: string;
  doS_StartDate: string;
  doS_EndDate: string;
  location: string;
  dependent: string;
  isSubscriberPatient: string;
  practiceTypeCode: string;
  referenceId: string;
  includeTextResponse: string;
  internalId: string;
  customerID: string;
  provider: {
    firstName: string;
    //middleName: string;
    lastName: string;
    npi: string;
    pin: string;
  };
  subscriber: {
    firstName: string;
    dob: string;
    lastName: string;
    memberID: string;
  };
}

export enum InsuranceType {
  Primary = "Primary",
  Secondary = "Secondary",
  Tertiary = "Tertiary",
}

export enum InsuranceSubscriber {
  Self = "Self",
  Other = "Other",
}
