import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { BroadcastEventTypes } from "src/app/core/models/lookup.model";
import { Patient } from "src/app/core/models/patient.model";
import { ApplicationCacheService } from "src/app/core/services/application-cache.service";
import { EventService } from "src/app/core/services/event.service";

@Component({
  selector: "app-patient-info-header",
  templateUrl: "./patient-info-header.component.html",
  styleUrls: ["./patient-info-header.component.scss"],
})
export class PatientInfoHeaderComponent {
  patient: Patient;
  physicianName: string;
  checkInTime: string;
  procedureText: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly _applicationCacheService: ApplicationCacheService,
    private readonly _eventService: EventService,
    private readonly _datePipe: DatePipe
  ) {
    this._eventService
      .on(BroadcastEventTypes.ProcedureCacheUpdated)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateProcedureText();
      });
    this._eventService
      .on(BroadcastEventTypes.ScheduleCacheUpdated)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateCheckInTime();
      });
    this._eventService
      .on(BroadcastEventTypes.PatientInfoUpdated)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.patient = this._applicationCacheService.applicationCache.patient;
      });
    this._eventService
      .on(BroadcastEventTypes.AppointmentUpdated)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.physicianName =
          this._applicationCacheService.applicationCache.appointment.providerName;
      });
  }

  ngOnInit() {
    this.patient = this._applicationCacheService.applicationCache.patient;
    this.physicianName =
      this._applicationCacheService.applicationCache.appointment.providerName;
    this.updateCheckInTime();
    this.updateProcedureText();
  }

  updateCheckInTime() {
    const { checkIn, scheduleDate } =
      this._applicationCacheService.applicationCache.schedule || {};
    if (!checkIn && !scheduleDate) {
      this.checkInTime = "";
      return;
    }
    const formattedDate = scheduleDate
      ? this._datePipe.transform(scheduleDate, "MM/dd/yyyy")
      : "";
    this.checkInTime = `${checkIn || ""} ${formattedDate}`;
  }

  updateProcedureText() {
    const { region, area } =
      this._applicationCacheService.applicationCache.procedure || {};
    if (!region && !area) {
      this.procedureText = "";
      return;
    }
    this.procedureText = `${region} ${area}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get selectedEncounter() {
    return this._applicationCacheService.applicationCache;
  }
}
