import { IEnvironment } from "src/app/core/models/environment.interface";

export const environment: IEnvironment = {
  production: false,
  loginUrl: 'https://dev-app.healthexam.com',
  remitAppUrl: 'https://wa-remit-v2-d-f32c1665b95c.herokuapp.com',
  adminAppUrl: 'https://dev-admin.healthexam.com',
  phiAppUrl: 'https://dev-patients.healthexam.com',
  studiesAppUrl: 'https://dev-studies.healthexam.com',
  billerAppUrl: 'https://dev-biller.healthexam.com',
  chartsAppUrl: 'https://dev-charts.healthexam.com',
  chartsV2AppUrl: 'https://dev-chartsv2.healthexam.com',
  apiBaseUrl: 'https://as-phi-v2-d-e036136e9efe.herokuapp.com/',
  adminApiBaseUrl: 'https://as-account-v2-d-90167a8f915a.herokuapp.com/',
  billerApiBaseUrl: 'https://as-biller-v2-d-6639a0a9ef39.herokuapp.com/',
  aiApiUrl: 'https://as-ai-v2-d-7c3b6b6e9c8b.herokuapp.com/',
  signatureKey: 'heathexamplatform@lo!@0oa8zj*4j8y=tx*6t$ytx-4=nqne8pbi8_tiv9l(8jaf('
}
