import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { environment } from "src/environments/environment";
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  map,
  of,
  tap,
} from "rxjs";
import { PatientDoc, UploadPatientDoc } from "../models/patient-docs.model";
import { ApplicationCacheService } from "./application-cache.service";
import { ApiStatus, ReferralApis } from "./utils";
import { cloneDeep } from "lodash";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class PatientDocsService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _toastService: ToastrService,
    private readonly _applicationCacheService: ApplicationCacheService
  ) {}

  private deleteSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  idToBeDeleted$: Observable<string> = this.deleteSubject$.asObservable();

  savePatientDoc(payload: UploadPatientDoc): Observable<PatientDoc> {
    // this is an extra endpoint because api/v1/documents does not return the inserted record
    //if case we need to return the inserted record we can use this endpoint
    //if you want all patient records returned, use the api/v1/documents api
    const url = this.apiBaseUrl + `api/v1/documents/add`;
    return this._dataService.post(url, payload);
  }

  getEncounterDocs(encounterId: string): Observable<PatientDoc[]> {
    if (this.isEncounterDocsAlreadyLoaded) {
      return of(this.documentCache);
    }
    const url = this.apiBaseUrl + `api/v1/documents/encounter/${encounterId}`;
    return this._dataService.get(url).pipe(
      catchError((error) => {
        this._toastService.error(
          error || "Unable to get Patient docs due to unknown reason.",
          "Error!"
        ),
          this._applicationCacheService.setApiStatus(
            ApiStatus.Failed,
            ReferralApis.Documents
          );
        return of(error);
      }),
      tap((patientDocs: PatientDoc[]) => {
        const docs = patientDocs.map((doc) => ({
          ...doc,
          isProcedureDocument: false, // phi currently does not supports procedural docs.
        }));
        this._applicationCacheService.setDocumentCache(docs);
        this._applicationCacheService.setApiStatus(
          ApiStatus.Success,
          ReferralApis.Documents
        );
      })
    );
  }

  deletePatientDoc(documentId: string): Observable<void> {
    this.deleteSubject$.next(documentId);
    const url = this.apiBaseUrl + `api/v1/documents/${documentId}`;
    return this._dataService
      .delete(url)
      .pipe(finalize(() => this.deleteSubject$.next(null)));
  }

  getDocumentLinkByFileName(fileName: string): Observable<string> {
    const url = this.apiBaseUrl + `api/v1/documents/view?key=${fileName}`;
    return this._dataService.get(url, { responseType: "text" });
  }

  readFileAndFetchDetails(fileKeyUrl: string): Observable<string> {
    const fileKey = fileKeyUrl.split("s3.amazonaws.com/")[1] || "";

    const prompt =
      '{"coumadin": "Add Lab value here...", "inr": "Add Lab value here...", "hemoglobin": "Add Lab value here...", "potassium": "Add Lab value here...", "creatinine": "Add Lab value here...", "calcium": "Add Lab value here...", "anGap": "Add Lab value here...", "bun": "Add Lab value here...", "carbonDioxide": "Add Lab value here...", "hgb": "Add Lab value here...", "hct": "Add Lab value here...", "sodium": "Add Lab value here...", "glucose": "Add Lab value here...", "chloride": "Add Lab value here...", "eGFR": "Add Lab value here...", "maxContrast": "Add Lab value here..." }';
    const url = this.apiUrlForAI + `api/v1/ai/ocr/${fileKey}`;
    return this._dataService.post(url, { fileKey, prompt });
  }

  get isEncounterDocsAlreadyLoaded(): boolean {
    return (
      this._applicationCacheService.getApiStatus(ReferralApis.Documents) ===
      ApiStatus.Success
    );
  }

  get documentCache(): PatientDoc[] {
    return cloneDeep(this._applicationCacheService.applicationCache.documents);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  get apiUrlForAI(): string {
    return environment.aiApiUrl;
  }
}
