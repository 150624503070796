import { Injectable } from "@angular/core";
import {
  ApiStatus,
  EncounterStatus,
  ProcedureRoomApis,
  ReferralApis,
} from "./utils";
import { EventService } from "./event.service";
import { ApplicationCache } from "../models/application-cache.model";
import { ProcedureRoomListing } from "../models/procedure-room.model";
import {
  BroadcastEventTypes,
  ChecklistQuestionsCache,
} from "../models/lookup.model";
import { NeurovascularAssessment } from "../models/neurovascular-assessment.model";
import {
  BloodGlucose,
  DispenseSupply,
  EncounterSchedule,
  LeadEKG,
  Medication,
  ModifiedAldreteScoring,
  Procedure,
  SnacksAndMeals,
  Vitals,
} from "../models/procedure.model";
import { environment } from "src/environments/environment";
import { PatientDoc } from "../models/patient-docs.model";
import { Appointment, AppointmentChecklist } from "../models/admissions.model";
import { PriorAuth } from "../models/prior-auth.model";
import { Coverage, InsuranceType } from "../models/coverages.model";
import { Patient } from "../models/patient.model";
import { OldLabInfo } from "../models/old-labs.model";
import { ReferralChecklist } from "../models/referral-checklist.model";
import { PreProcedureAssessment } from "../models/pre-procedure-assessment";
import { Allergy } from "../models/allergy.model";
import { AdministerMedication } from "../models/administer-medication.model";

@Injectable({
  providedIn: "root",
})
export class ApplicationCacheService {
  applicationCache: ApplicationCache;
  private apiStatuses: Map<string, ApiStatus>;
  constructor(private readonly _eventService: EventService) {
    this.clearSelection();
  }

  setApiStatus(status: ApiStatus, api: string) {
    this.apiStatuses.set(api, status);
  }

  getApiStatus(api: string): ApiStatus {
    return this.apiStatuses.get(api);
  }

  setAllApisStatus(status: ApiStatus) {
    for (let key of this.apiStatuses.keys()) {
      this.setApiStatus(status, key);
    }
  }

  setDocumentCache(docs: PatientDoc[]) {
    this.applicationCache.documents = docs;
    this._eventService.broadcast(BroadcastEventTypes.PatientDocsLoaded);
  }

  addDocumentToCache(doc: PatientDoc) {
    this.applicationCache.documents = [...this.applicationCache.documents, doc];
  }

  removePatientDocFromCache(docId: string) {
    this.applicationCache.documents = this.applicationCache.documents.filter(
      (doc) => doc.id !== docId
    );
  }

  updateScheduleCache(schedule: EncounterSchedule) {
    this.applicationCache.schedule = schedule;
    this._eventService.broadcast(BroadcastEventTypes.ScheduleCacheUpdated);
  }

  updateProcedureCache(procedure: Procedure) {
    this.applicationCache.procedure = procedure;
    this._eventService.broadcast(BroadcastEventTypes.ProcedureCacheUpdated, {
      ...procedure,
    });
  }

  updateAppointmentCache(updatedAppointment: Appointment) {
    this.applicationCache.appointment = updatedAppointment;
    this._eventService.broadcast(
      BroadcastEventTypes.AppointmentUpdated,
      updatedAppointment
    );
  }

  updatePriorAuthCache(updatedPriorAuth: PriorAuth) {
    this.applicationCache.priorAuth = updatedPriorAuth;
    this._eventService.broadcast(
      BroadcastEventTypes.PriorAuthUpdated,
      updatedPriorAuth
    );
  }

  updateCoverageCache(updatedCoverages: Coverage[]) {
    this.applicationCache.coverages = updatedCoverages;
    this._eventService.broadcast(
      BroadcastEventTypes.CoverageUpdated,
      updatedCoverages
    );
  }

  updatePatientInfoCache(patient: Patient) {
    this.applicationCache.patient = patient;
    this._eventService.broadcast(
      BroadcastEventTypes.PatientInfoUpdated,
      patient
    );
  }

  updateChiefComplaintCache(updatedChiefComplaint: string) {
    this.applicationCache.chiefComplaint = updatedChiefComplaint;
    this._eventService.broadcast(
      BroadcastEventTypes.ChiefComplaintUpdated,
      updatedChiefComplaint
    );
  }

  setChecklistQuestionsCache(questions: ChecklistQuestionsCache[]) {
    this.applicationCache.checklistQuestions = questions;
    this._eventService.broadcast(BroadcastEventTypes.ChecklistQuestionsUpdated);
  }

  setLabInfoCache(labs: OldLabInfo[]) {
    this.applicationCache.labs = labs || [];
    this._eventService.broadcast(BroadcastEventTypes.LabsInfoUpdated);
  }

  updateLabInfoCache(labInfo: OldLabInfo) {
    const matchingLabInfo = this.applicationCache.labs.find(
      (x) => x.id === labInfo.id
    );
    if (!matchingLabInfo) {
      this.applicationCache.labs = [...this.applicationCache.labs, labInfo];
    } else {
      Object.assign(matchingLabInfo, labInfo);
    }
    this._eventService.broadcast(BroadcastEventTypes.LabsInfoUpdated);
  }

  updateAppointmentChecklistFlags(checklist: AppointmentChecklist) {
    this.applicationCache.checklistFlags = checklist;
  }

  updateAppointmentChecklistValues(checklist: ReferralChecklist) {
    this.applicationCache.checklistValues = checklist;
  }

  broadcastProgressUpdate() {
    this._eventService.broadcast(BroadcastEventTypes.RequiresProgressUpdate);
  }

  getExpectedAppointmentStatus(): string {
    const isScheduled = !!this.applicationCache.schedule?.id;
    const hasCoverage = !!this.applicationCache.coverages?.length;
    const isAuthRequired =
      this.applicationCache.coverages?.find(
        (c) => c.insuranceType === InsuranceType.Primary
      )?.requiresAuth === true;
    const hasPriorAuth = !!this.applicationCache.priorAuth?.id;

    if (hasCoverage) {
      if (isAuthRequired) {
        return hasPriorAuth
          ? EncounterStatus.Authorized
          : EncounterStatus.PendingAuth;
      }
      return EncounterStatus.NoAuthRequired;
    }
    return isScheduled
      ? EncounterStatus.Scheduled
      : EncounterStatus.NewReferral;
  }

  setProcedureRoomListingCache(procedureListing: ProcedureRoomListing) {
    this.applicationCache.procedureListing = procedureListing;
    this._eventService.broadcast(
      BroadcastEventTypes.ProcedureRoomListingUpdated
    );
  }

  setNeurovascularAssessmentCache(neuro: NeurovascularAssessment) {
    this.applicationCache.neurovascularAssessment = neuro;
    this._eventService.broadcast(
      BroadcastEventTypes.NeurovascularAssessmentUpdated
    );
  }

  setSnacksAndMealsCache(snacks: SnacksAndMeals[]) {
    this.applicationCache.snacksAndMeals = snacks;
    this._eventService.broadcast(BroadcastEventTypes.SnacksAndMealsUpdated);
  }

  updateSnacksAndMealsCache(snacks: SnacksAndMeals) {
    const matchingRecord = this.applicationCache.snacksAndMeals.find(
      (x) => x.id === snacks.id
    );

    if (matchingRecord) {
      Object.assign(matchingRecord, snacks);
    } else {
      this.applicationCache.snacksAndMeals.push({ ...snacks });
    }
    this._eventService.broadcast(BroadcastEventTypes.SnacksAndMealsUpdated);
  }

  setDispenseSuppliesCache(supplies: DispenseSupply[]) {
    this.applicationCache.dispenseSupplies = supplies;
    this._eventService.broadcast(BroadcastEventTypes.DispenseSuppliesUpdated);
  }

  updateDispenseSuppliesCache(supply: DispenseSupply) {
    const matchingRecord = this.applicationCache.dispenseSupplies.find(
      (x) => x.id === supply.id
    );

    if (matchingRecord) {
      Object.assign(matchingRecord, supply);
    } else {
      this.applicationCache.dispenseSupplies.push({ ...supply });
    }
    this._eventService.broadcast(BroadcastEventTypes.SnacksAndMealsUpdated);
  }

  setVitalsCache(vitals: Vitals[]) {
    this.applicationCache.vitals = vitals;
    this._eventService.broadcast(BroadcastEventTypes.VitalsUpdated);
  }

  updateVitalsCache(vitals: Vitals) {
    const matchingRecord = this.applicationCache.vitals.find(
      (x) => x.id === vitals.id
    );

    if (matchingRecord) {
      Object.assign(matchingRecord, vitals);
    } else {
      this.applicationCache.vitals.push({ ...vitals });
    }
    this._eventService.broadcast(BroadcastEventTypes.VitalsUpdated);
  }

  setAllergiesCache(allergies: Allergy[]) {
    this.applicationCache.allergies = allergies;
    this._eventService.broadcast(BroadcastEventTypes.AllergiesUpdated);
  }

  updateAllergiesCache(allergy: Allergy) {
    const matchingRecord = this.applicationCache.allergies.find(
      (x) => x.id === allergy.id
    );

    if (matchingRecord) {
      Object.assign(matchingRecord, allergy);
    } else {
      this.applicationCache.allergies.push({ ...allergy });
    }
    this._eventService.broadcast(BroadcastEventTypes.AllergiesUpdated);
  }

  setBloodGlucoseCache(bloodGlucose: BloodGlucose[]) {
    this.applicationCache.bloodGlucose = bloodGlucose;
    this._eventService.broadcast(BroadcastEventTypes.BloodGlucoseUpdated);
  }

  updateBloodGlucoseCache(bloodGlucose: BloodGlucose) {
    const matchingRecord = this.applicationCache.bloodGlucose.find(
      (x) => x.id === bloodGlucose.id
    );

    if (matchingRecord) {
      Object.assign(matchingRecord, bloodGlucose);
    } else {
      this.applicationCache.bloodGlucose.push({ ...bloodGlucose });
    }
    this._eventService.broadcast(BroadcastEventTypes.BloodGlucoseUpdated);
  }

  setModifiedAldreteScoringCache(
    modifiedAldreteScoring: ModifiedAldreteScoring
  ) {
    this.applicationCache.modifiedAldreteScoring = modifiedAldreteScoring;
    this._eventService.broadcast(
      BroadcastEventTypes.ModifiedAldreteScoringUpdated
    );
  }

  setLeadEKGCache(leadEKG: LeadEKG[]) {
    this.applicationCache.leadEKG = leadEKG;
    this._eventService.broadcast(BroadcastEventTypes.LeadEKGUpdated);
  }

  updateLeadEKGCache(leadEKG: LeadEKG) {
    const matchingRecord = this.applicationCache.leadEKG.find(
      (x) => x.id === leadEKG.id
    );

    if (matchingRecord) {
      Object.assign(matchingRecord, leadEKG);
    } else {
      this.applicationCache.leadEKG.push({ ...leadEKG });
    }
    this._eventService.broadcast(BroadcastEventTypes.LeadEKGUpdated);
  }

  setMedicationReconciliationCache(medicationReconciliation: Medication[]) {
    this.applicationCache.medicationReconciliation = medicationReconciliation;
    this._eventService.broadcast(
      BroadcastEventTypes.MedicationReconciliationUpdated
    );
  }

  updateMedicationReconciliationCache(medication: Medication) {
    const matchingRecord = this.applicationCache.medicationReconciliation.find(
      (x) => x.id === medication.id
    );

    if (matchingRecord) {
      Object.assign(matchingRecord, medication);
    } else {
      this.applicationCache.medicationReconciliation.push({ ...medication });
    }
    this._eventService.broadcast(
      BroadcastEventTypes.MedicationReconciliationUpdated
    );
  }

  setAdministerMedsCache(administerMeds: AdministerMedication[]) {
    this.applicationCache.administerMeds = administerMeds;
    this._eventService.broadcast(BroadcastEventTypes.AdministerMedsUpdated);
  }

  updateAdministerMedsCache(medication: AdministerMedication) {
    const matchingRecord = this.applicationCache.administerMeds.find(
      (x) => x.id === medication.id
    );

    if (matchingRecord) {
      Object.assign(matchingRecord, medication);
    } else {
      this.applicationCache.administerMeds.push({ ...medication });
    }
    this._eventService.broadcast(BroadcastEventTypes.AdministerMedsUpdated);
  }

  setPreProcedureAssessmentCache(
    preProcedureAssessment: PreProcedureAssessment
  ) {
    this.applicationCache.preProcedureAssessment = preProcedureAssessment;
    this._eventService.broadcast(
      BroadcastEventTypes.PreProcedureAssessmentUpdated
    );
  }

  clearSelection() {
    this.applicationCache = new ApplicationCache();
    this.apiStatuses = new Map<string, ApiStatus>([
      [ReferralApis.Patient, ApiStatus.Pending],
      [ReferralApis.Documents, ApiStatus.Pending],
      [ReferralApis.PriorAuth, ApiStatus.Pending],
      [ReferralApis.Coverages, ApiStatus.Pending],
      [ReferralApis.ChecklistFlags, ApiStatus.Pending],
      [ReferralApis.ChecklistQuestions, ApiStatus.Pending],
      [ReferralApis.ChecklistValues, ApiStatus.Pending],
      [ReferralApis.Labs, ApiStatus.Pending],
      [ReferralApis.ChiefComplaint, ApiStatus.Pending],
      [ProcedureRoomApis.Patient, ApiStatus.Pending],
      [ProcedureRoomApis.NeurovascularAssessment, ApiStatus.Pending],
      [ProcedureRoomApis.SnacksAndMeals, ApiStatus.Pending],
      [ProcedureRoomApis.Vitals, ApiStatus.Pending],
      [ProcedureRoomApis.BloodGlucose, ApiStatus.Pending],
      [ProcedureRoomApis.DispenseSupplies, ApiStatus.Pending],
      [ProcedureRoomApis.LeadEKG, ApiStatus.Pending],
      [ProcedureRoomApis.ModifiedAldreteScoring, ApiStatus.Pending],
      [ProcedureRoomApis.MedicationReconciliation, ApiStatus.Pending],
      [ProcedureRoomApis.PreProcedureAssessment, ApiStatus.Pending],
      [ProcedureRoomApis.AdministerMedication, ApiStatus.Pending],
    ]);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }
}
