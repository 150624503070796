<div class="mb-2 mt-2" *ngFor="let q of questions">
  <div class="d-flex align-items-center justify-content-between">
    <label
      class="form-label question m-0"
      [ngClass]="{ 'fw-bold': q.value && q.value.trim() !== '' }"
      >{{ q.title }}</label
    >
    <div *ngIf="!showConfirmationButton" class="d-flex align-items-center">
      <div
        class="check-list-option me-2 is-yes"
        [ngClass]="{ selected: q.answer === true }"
        role="button"
        (click)="selectionChanged(q, true)"
      >
        <label
          role="button"
          class="m-0"
          for="question-yes-input-{{ q.questionId }}"
          >Yes</label
        >
      </div>
      <div
        class="check-list-option me-2 btn-no"
        [ngClass]="{ selected: q.answer === false }"
        role="button"
        (click)="selectionChanged(q, false)"
      >
        <label
          role="button"
          class="m-0"
          for="question-no-input-{{ q.questionId }}"
          >No</label
        >
      </div>
      <div
        *ngIf="showNotAvailableOption"
        class="check-list-option btn-no"
        [ngClass]="{ selected: q.answer === null }"
        role="button"
        (click)="selectionChanged(q, null)"
      >
        <label
          role="button"
          class="m-0"
          for="question-na-input-{{ q.questionId }}"
          >NA</label
        >
      </div>
    </div>
    <div *ngIf="showConfirmationButton">
      <div
        class="confirmation-button"
        [ngClass]="{ selected: q.answer }"
        (click)="confirmUnconfirm(q)"
        role="button"
      >
        {{ q.answer ? "Confirmed" : "Confirm" }}
      </div>
    </div>
  </div>
  <div *ngIf="showDescription && q.answer">
    <textarea
      class="form-control mt-2"
      rows="2"
      name="description-input-{{ q.questionId }}"
      [(ngModel)]="q.value"
      [ngClass]="{
        'textarea-empty': !q.value || q.value.trim() === '',
        'textarea-filled': q.value && q.value.trim() !== ''
      }"
    ></textarea>
  </div>
</div>
