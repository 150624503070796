import {
  EnumerationItem,
  SelectableEnumerationItem,
} from "../models/lookup.model";
import { DateTime } from "luxon";
import { EventInput } from "@fullcalendar/core";
import { EncounterSchedule } from "../models/procedure.model";

export class Utils {
  static parseJwt(token: string): any {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  static generateUniqueValue(): string {
    const timestamp = Date.now();
    const random = Math.random().toString(36).substring(2, 8); // Random string
    return `-${timestamp}-${random}`;
  }

  static getTokenExpireDate(exp: number) {
    let expiresDate = new Date(0);
    expiresDate.setUTCMilliseconds(exp * 1000);

    return expiresDate;
  }

  static roundNumber(value: number): number {
    const threshold: number = 0.1;
    const decimalPart = value % 1;

    if (Math.abs(decimalPart) < threshold) {
      return Math.round(value); // Return as an integer
    }

    return parseFloat(value.toFixed(2));
  }

  static calculateAge(dob: string): number {
    // Parse the input date of birth
    const birthDate = new Date(dob);
    if (isNaN(birthDate.getTime())) {
      return 0;
    }

    // Get today's date
    const today = new Date();

    // Calculate the age
    let age = today.getFullYear() - birthDate.getFullYear();

    // Adjust age if the birthday hasn't occurred yet this year
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  static splitCamelCase(value: string | undefined): string {
    return value?.replace(/([a-z])([A-Z])/g, "$1 $2") ?? "";
  }

  static getInitialsFromFullName(fullName: string) {
    const nameArray = fullName.split(" ");
    const initials = nameArray
      .map((name) => name.charAt(0).toUpperCase())
      .join("");
    return initials;
  }

  static toTitleCase(value: string): string {
    if (!value) {
      return "";
    }
    return value.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }

  static formatISODateWithoutTime(date: Date) {
    let month: number | string = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day: number | string = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    return date.getFullYear() + "-" + month + "-" + day + "T00:00:00";
  }

  static getStartOfDayISODate(date: Date): string {
    let month: number | string = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day: number | string = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    return date.getFullYear() + "-" + month + "-" + day + "T00:00:00";
  }

  static getEndOfDayISODate(date: Date): string {
    let month: number | string = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day: number | string = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    return date.getFullYear() + "-" + month + "-" + day + "T23:59:59";
  }

  static getStartOfWeek() {
    return DateTime.now().startOf("week").startOf("day").toJSDate();
  }

  static getEndOfWeek() {
    return DateTime.now().endOf("week").endOf("day").toJSDate();
  }
  static getStartOfMonth() {
    return DateTime.now().startOf("month").startOf("day").toJSDate();
  }

  static getEndOfMonth() {
    return DateTime.now().endOf("month").endOf("day").toJSDate();
  }

  static MinsToHm = (minsFromMidNight: number): string => {
    minsFromMidNight = Number(minsFromMidNight);
    const h = Math.floor(minsFromMidNight / 60);
    const m = minsFromMidNight % 60;
    const formattedHours =
      h.toString().length === 1 ? "0" + h.toString() : h.toString();
    const formattedMins =
      m.toString().length === 1 ? "0" + m.toString() : m.toString();
    return `${formattedHours}:${formattedMins}`;
  };

  static HmToMins = (formattedTime: string): number => {
    const hmString = formattedTime.split(":");
    if (hmString.length !== 2) {
      return 0;
    }
    const hrs = Number(hmString[0]);
    const mins = Number(hmString[1]);
    return hrs * 60 + mins;
  };

  static getTimeInMinutesFromMidnight(date: Date): number {
    if (!(date instanceof Date)) {
      return 0;
    }

    const hours = date.getHours();
    const minutes = date.getMinutes();

    return hours * 60 + minutes;
  }

  static formatPhoneNumber = (number: string): string => {
    let cleaned = ("" + number).replace(/\D/g, ""),
      match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{3}|\d{4})$/);
    if (match) {
      let intlCode = match[1] ? "+1 " : "";
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return number;
  };

  static getEnumerationItemsFromDayOfWeekEnum() {
    return Object.keys(DayOfWeek).map((key) => ({
      value: DayOfWeek[key],
      displayName: key,
    }));
  }

  static getEnumerationItemsFromMaritalStatusEnum() {
    return Object.keys(MaritalStatus).map((key) => ({
      value: MaritalStatus[key],
      displayName: key,
    }));
  }

  static getEnumerationItemsFromMedicationStatusEnum() {
    return Object.keys(MedicationStatus).map((key) => ({
      value: MedicationStatus[key],
      displayName: key,
    }));
  }

  static getEnumerationItemsFromGenderEnum() {
    return Object.keys(Gender).map((key) => ({
      value: Gender[key],
      displayName: key,
    }));
  }

  static getEnumerationItemsFromEthnicityEnum() {
    return Object.keys(Ethnicity).map((key) => ({
      value: Ethnicity[key],
      displayName: key,
    }));
  }

  static getEnumerationItemsFromRelationshipEnum() {
    return Object.keys(Relationship).map((key) => ({
      value: key,
      displayName: Relationship[key],
    }));
  }

  static getEnumerationItemsFromEncounerStatusEnum(): SelectableEnumerationItem[] {
    return Object.keys(EncounterStatus).map((key) => ({
      value: EncounterStatus[key],
      displayName: EncounterStatus[key],
      isSelected: true,
    }));
  }

  static getEnumerationItemsFromUploadDocumentTypeEnum() {
    return Object.keys(UploadDocumentType).map((key) => ({
      value: UploadDocumentType[key],
      displayName: UploadDocumentType[key],
    }));
  }

  static getEnumerationItemsFromRegionEnum() {
    return Object.keys(Region).map((key) => ({
      value: Region[key],
      displayName: Region[key],
    }));
  }

  static getEnumerationItemsFromAreaEnum() {
    return Object.keys(Area).map((key) => ({
      value: Area[key],
      displayName: Area[key],
    }));
  }

  static getEnumerationItemsFromSideEnum() {
    return Object.keys(Side).map((key) => ({
      value: Side[key],
      displayName: Side[key],
    }));
  }

  static getCptCodesForType(type: CptCodeType) {
    switch (type) {
      case CptCodeType.Carotid:
        return this.getCaroidCptCodes();
      case CptCodeType.Artery:
        return this.getArteryCptCodes();
      case CptCodeType.Vein:
        return this.getVeinCptCodes();
    }
  }

  static getEnumerationItemsFromLocationEnum(
    selectedRegion: Region | undefined
  ) {
    return [
      {
        value: Location.DistalAbdominal,
        displayName: Location.DistalAbdominal,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.CommonIliac,
        displayName: Location.CommonIliac,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.ExternalIliac,
        displayName: Location.ExternalIliac,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.InternalIliac,
        displayName: Location.InternalIliac,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.DeepFemoralProfunda,
        displayName: Location.DeepFemoralProfunda,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.CommonFemoral,
        displayName: Location.CommonFemoral,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.ProxSuperficialFemoral,
        displayName: Location.ProxSuperficialFemoral,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.MidSuperficialFemoral,
        displayName: Location.MidSuperficialFemoral,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.DisSuperficialFemoral,
        displayName: Location.DisSuperficialFemoral,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.Popliteal,
        displayName: Location.Popliteal,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.AnteriorTibial,
        displayName: Location.AnteriorTibial,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.TibialPeronealTrunk,
        displayName: Location.TibialPeronealTrunk,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.PosteriorTibial,
        displayName: Location.PosteriorTibial,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.Peroneal,
        displayName: Location.Peroneal,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.DorsalisPedis,
        displayName: Location.DorsalisPedis,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.Planter,
        displayName: Location.Planter,
        parentValue: Region.LowerExtremity,
      },
      {
        value: Location.Retroduodenal,
        displayName: Location.Retroduodenal,
        parentValue: Region.Biliary,
      },
      {
        value: Location.Gastroduodenal,
        displayName: Location.Gastroduodenal,
        parentValue: Region.Biliary,
      },
      {
        value: Location.RightBranchHepatic,
        displayName: Location.RightBranchHepatic,
        parentValue: Region.Biliary,
      },
      {
        value: Location.Cystic,
        displayName: Location.Cystic,
        parentValue: Region.Biliary,
      },
      {
        value: Location.CeliacTrunk,
        displayName: Location.CeliacTrunk,
        parentValue: Region.Biliary,
      },
      {
        value: Location.SuperiorMesenteric,
        displayName: Location.SuperiorMesenteric,
        parentValue: Region.Biliary,
      },
      {
        value: Location.InferiorMesenteric,
        displayName: Location.InferiorMesenteric,
        parentValue: Region.Biliary,
      },
      {
        value: Location.Radial,
        displayName: Location.Radial,
        parentValue: Region.UpperExtremity,
      },
      {
        value: Location.Ulnar,
        displayName: Location.Ulnar,
        parentValue: Region.UpperExtremity,
      },
      {
        value: Location.Brachial,
        displayName: Location.Brachial,
        parentValue: Region.UpperExtremity,
      },
      {
        value: Location.Brachialcephalic,
        displayName: Location.Brachialcephalic,
        parentValue: Region.UpperExtremity,
      },
      {
        value: Location.Subclavian,
        displayName: Location.Subclavian,
        parentValue: Region.UpperExtremity,
      },
    ].filter((c) => !selectedRegion || c.parentValue === selectedRegion);
  }
  static getEnumerationItemsFromProcedureTypeEnum() {
    return Object.keys(ProcedureType).map((key) => ({
      value: ProcedureType[key],
      displayName: ProcedureType[key],
    }));
  }

  static getEnumerationItemsFromLabTestEnum() {
    return Object.keys(LabTest).map((key) => ({
      value: LabTest[key],
      displayName: LabTest[key],
    }));
  }

  private static getCaroidCptCodes(): cptCodesForPriorAuth[] {
    return [
      {
        code: "36221",
        tooltip:
          "CPT 36221 - Selective catheter placement, arterial system; each first order abdominal, pelvic, or lower extremity artery branch, within a vascular family",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36222",
        tooltip:
          "CPT 36222 - Selective catheter placement, arterial system; initial second order abdominal, pelvic, or lower section artery branch",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36223",
        tooltip:
          "CPT 36223 - Selective catheter placement, arterial system; initial third order or more selective abdominal, pelvic, or lower extremity artery branch, within a vascular family",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36224",
        tooltip:
          "CPT 36224 - Selective catheter placement, arterial system; initial fourth order or more selective abdominal, pelvic, or lower extremity artery branch, within a vascular family",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36225",
        tooltip:
          "CPT 36225 - Selective catheter placement, arterial system; initial third order or more selective renal or brachial artery branch, within a vascular family",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36226",
        tooltip:
          "CPT 36226 - Selective catheter placement, arterial system; initial fourth order or more selective renal or brachial artery branch, within a vascular family",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36227",
        tooltip:
          "CPT 36227 - Selective catheter placement, arterial system; initial third order or more selective head or neck artery branch, within a vascular family",
        section: CptCodeSection.Diagnostics,
      },
    ];
  }
  private static getArteryCptCodes(): cptCodesForPriorAuth[] {
    return [
      {
        code: "36140",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36200",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36245",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36246",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36247",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "37252",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "37253",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "75610",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "75625",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "75630",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "37220",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37221",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37222",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37223",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37224",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37225",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37226",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37227",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37228",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37229",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37230",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37231",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
    ];
  }
  private static getVeinCptCodes(): cptCodesForPriorAuth[] {
    return [
      {
        code: "36010",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36011",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "36012",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "37252",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "37253",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "75820",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "75822",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "75825",
        tooltip: "",
        section: CptCodeSection.Diagnostics,
      },
      {
        code: "37238",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37239",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37248",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
      {
        code: "37249",
        tooltip: "",
        section: CptCodeSection.Interventions,
      },
    ];
  }

  static getLabQuestions(): LabQuestion[] {
    return [
      {
        id: "potassium",
        question: "Potassium is less then 5?",
      },
      {
        id: "hemoglobin",
        question: "Hemoglobin is greater then 8?",
      },
      {
        id: "creatinine",
        question: "Is Creatinine less then 1.5?",
      },
      {
        id: "coumadin",
        question: "This patient is currently taking Coumadin?",
      },
      {
        id: "inr",
        question: "Confirm INR is less then 1.7?",
        dependentOn: "coumadin",
      },
    ];
  }

  static getTimeSlotList(): EnumerationItem[] {
    return [
      { value: "300", displayName: "5:00 AM" },
      { value: "330", displayName: "5:30 AM" },
      { value: "360", displayName: "6:00 AM" },
      { value: "390", displayName: "6:30 AM" },
      { value: "420", displayName: "7:00 AM" },
      { value: "450", displayName: "7:30 AM" },
      { value: "480", displayName: "8:00 AM" },
      { value: "510", displayName: "8:30 AM" },
      { value: "540", displayName: "9:00 AM" },
      { value: "570", displayName: "9:30 AM" },
      { value: "600", displayName: "10:00 AM" },
      { value: "630", displayName: "10:30 AM" },
      { value: "660", displayName: "11:00 AM" },
      { value: "690", displayName: "11:30 AM" },
      { value: "720", displayName: "12:00 PM" },
      { value: "750", displayName: "12:30 PM" },
      { value: "780", displayName: "01:00 PM" },
      { value: "810", displayName: "01:30 PM" },
      { value: "840", displayName: "02:00 PM" },
      { value: "870", displayName: "02:30 PM" },
      { value: "900", displayName: "03:00 PM" },
      { value: "930", displayName: "03:30 PM" },
      { value: "960", displayName: "04:00 PM" },
      { value: "990", displayName: "04:30 PM" },
      { value: "1020", displayName: "05:00 PM" },
      { value: "1050", displayName: "05:30 PM" },
      { value: "1080", displayName: "06:00 PM" },
      { value: "1110", displayName: "06:30 PM" },
      { value: "1140", displayName: "07:00 PM" },
      { value: "1170", displayName: "07:30 PM" },
      { value: "1200", displayName: "08:00 PM" },
      { value: "1230", displayName: "08:30 PM" },
      { value: "1260", displayName: "09:00 PM" },
      { value: "1290", displayName: "09:30 PM" },
      { value: "1320", displayName: "10:00 PM" },
      { value: "1350", displayName: "10:30 PM" },
      { value: "1380", displayName: "11:00 PM" },
    ];
  }

  static getActivityAbleToMove(): EnumerationItem[] {
    return [
      { value: "2", displayName: "4 extremities" },
      { value: "1", displayName: "2 extremities" },
      { value: "0", displayName: "0 extremities" },
    ];
  }

  static getRespiration(): EnumerationItem[] {
    return [
      { value: "2", displayName: "Able to breathe and cough freely" },
      { value: "1", displayName: "Dyspnea, shallow or limited breathing" },
      { value: "0", displayName: "Apneic" },
    ];
  }

  static getCirculation(): EnumerationItem[] {
    return [
      { value: "2", displayName: "BP ± 20 mmHg of pre-sedation level" },
      { value: "1", displayName: "BP ± 20 -50 mmHg of pre-sedation level" },
      { value: "0", displayName: "BP ± 50 of pre-sedation level" },
    ];
  }

  static getConsciousness(): EnumerationItem[] {
    return [
      { value: "2", displayName: "Fully Awake" },
      { value: "1", displayName: "Arousable on calling" },
      { value: "0", displayName: "Not responding" },
    ];
  }

  static getO2Situration(): EnumerationItem[] {
    return [
      {
        value: "2",
        displayName: "Able to maintain O2 saturation & 92% on room air",
      },
      { value: "1", displayName: "Needs O2 to maintain Saturation & 90%" },
      { value: "0", displayName: "O2 Saturation" },
    ];
  }

  static getNVAPulseLocation(): EnumerationItem[] {
    return [
      { value: "Brachial", displayName: "Brachial" },
      { value: "Femoral", displayName: "Femoral" },
      { value: "Popliteal", displayName: "Popliteal" },
      { value: "Radial", displayName: "Radial" },
      { value: "Dorsalis Pedis", displayName: "Dorsalis Pedis" },
      { value: "Posterior Tibial", displayName: "Posterior Tibial" },
    ];
  }

  static getNVAPulseTypes(): EnumerationItem[] {
    return [
      { value: "Absent", displayName: "Absent" },
      { value: "Faint", displayName: "Faint" },
      { value: "+1", displayName: "+1" },
      { value: "+2", displayName: "+2" },
      { value: "+3", displayName: "+3" },
      { value: "+4", displayName: "+4" },
    ];
  }

  static getPainLevels(): EnumerationItem[] {
    return [
      { value: "1", displayName: "1" },
      { value: "2", displayName: "2 " },
      { value: "3", displayName: "3 " },
      { value: "4", displayName: "4 " },
      { value: "5", displayName: "5 " },
      { value: "6", displayName: "6 " },
      { value: "7", displayName: "7 " },
      { value: "8", displayName: "8 " },
      { value: "9", displayName: "9 " },
      { value: "10", displayName: "10" },
    ];
  }

  static getSnacksAndMeals(): EnumerationItem[] {
    return [
      { value: "1", displayName: "Apple" },
      { value: "2", displayName: "Bagel" },
      { value: "3", displayName: "Honey Gram Sticks" },
      { value: "4", displayName: "Juice Box" },
      { value: "5", displayName: "Mini Kind Bars" },
    ];
  }
  static getDispenseSupply(): EnumerationItem[] {
    return [
      { value: "1", displayName: "Bedpan" },
      { value: "2", displayName: "Bath blanket" },
      { value: "3", displayName: "Emesis Basin" },
      { value: "4", displayName: "Pillow case" },
    ];
  }

  static getQuickProcedureTypes(): EnumerationItem[] {
    return [
      { value: "Arterial", displayName: "Arterial" },
      { value: "Venous", displayName: "Venous" },
      { value: "AV Fistula", displayName: "AV Fistula" },
      { value: "Cardiac", displayName: "Cardiac" },
    ];
  }

  static getCalendarEvents(schedules: EncounterSchedule[]): EventInput[] {
    return schedules.flatMap((schedule) => {
      // Check-In Event
      const checkInEvent = this.createEventDateRange(
        schedule.scheduleDate,
        schedule.checkIn,
        Number(schedule.preProcedureMin) || 0
      );

      // Procedure Event
      const procedureEvent = this.createEventDateRange(
        schedule.scheduleDate,
        schedule.procedureTime,
        Number(schedule.procedureMin) || 0
      );

      // Check-Out Event
      const checkOutEvent = this.createEventDateRange(
        schedule.scheduleDate,
        schedule.checkOut,
        Number(schedule.checkOutMin) || 0
      );

      return [
        {
          start: checkInEvent.start.toISOString(),
          end: checkInEvent.end.toISOString(),
          title: "",
          resourceId: schedule.bedNo || null,
          type: ScheduledEventType.CheckIn,
          scheduleId: schedule.id,
          bedNo: schedule?.bedNo || null,
          hasResource: !!schedule?.bedNo,
          eventLabel: schedule?.patientName,
          formattedTime: DateTime.fromJSDate(checkInEvent.start).toFormat(
            "hh:mm"
          ),
        },
        {
          start: procedureEvent.start.toISOString(),
          end: procedureEvent.end.toISOString(),
          title: schedule.patientName || "",
          resourceId: schedule?.bedNo || null,
          type: ScheduledEventType.Procedure,
          scheduleId: schedule.id,
          bedNo: schedule?.bedNo,
          hasResource: !!schedule?.bedNo,
          eventLabel: schedule?.patientName,
          formattedTime: DateTime.fromJSDate(checkInEvent.start).toFormat(
            "hh:mm"
          ),
        },
        {
          start: checkOutEvent.start.toISOString(),
          end: checkOutEvent.end.toISOString(),
          title: "",
          resourceId: schedule?.bedNo || null,
          type: ScheduledEventType.CheckOut,
          scheduleId: schedule.id,
          bedNo: schedule?.bedNo,
          hasResource: !!schedule?.bedNo,
          eventLabel: schedule?.patientName,
          formattedTime: DateTime.fromJSDate(checkInEvent.start).toFormat(
            "hh:mm"
          ),
        },
      ];
    });
  }

  static createEventDateRange(
    date: string,
    time: string,
    durationMinutes: number
  ): { start: Date; end: Date } {
    const { hour, minute } = this.getHourMins(time);
    const startDate = DateTime.fromFormat(date, "yyyy-MM-dd").set({
      hour,
      minute,
      second: 0,
      millisecond: 0,
    });
    const endDate = startDate.plus({ minutes: durationMinutes || 0 });

    return {
      start: startDate.toJSDate(),
      end: endDate.toJSDate(),
    };
  }

  static getHourMins(timeString: string): {
    hour: number;
    minute: number;
  } {
    // Split the time into hours, minutes, and period (AM/PM)
    const [time, period] = timeString.split(" ");
    const [checkInHour, checkInMinute] = time.split(":");
    // Convert hours to 24-hour format if needed
    let hoursIn24Format = parseInt(checkInHour, 10);
    if (period === "PM" && hoursIn24Format !== 12) {
      hoursIn24Format += 12;
    } else if (period === "AM" && hoursIn24Format === 12) {
      hoursIn24Format = 0;
    }
    return { hour: hoursIn24Format, minute: Number(checkInMinute) };
  }
}

export interface LabQuestion {
  id: string;
  question: string;
  dependentOn?: string;
  answer?: "Yes" | "No";
}

export interface cptCodesForPriorAuth {
  code: string;
  tooltip: string;
  section: CptCodeSection;
  isSelected?: boolean;
}

export enum MedicationStatus {
  Active = "active",
  InActive = "inactive",
}

export enum DayOfWeek {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum MaritalStatus {
  Single = "Single",
  Married = "Married",
  Divorced = "Divorced",
  Widowed = "Widowed",
  Separated = "Separated",
}

export enum Gender {
  Male = "Male",
  Female = "Female",
}

export enum Ethnicity {
  AmericanIndianOrAlaskaNative = "American Indian or Alaska Native",
  Asian = "Asian",
  BlackOrAfricanAmerican = "Black or African American",
  White = "White",
  Unknown = "Unknown",
}

export enum Relationship {
  GrandFather = "Grand Father",
  GrandMother = "Grand Mother",
  Father = "Father",
  Mother = "Mother",
  Son = "Son",
  Daughter = "Daughter",
  Spouse = "Spouse",
  Sibling = "Sibling",
  Uncle = "Uncle",
  Aunt = "Aunt",
}

export enum PatientStatus {
  Admitted = "admitted",
}

export enum ViewMode {
  List = 1,
  Calendar,
}

export enum CalendarFilter {
  Day = 1,
  Week,
  Month,
}

export enum EncounterStatus {
  NewReferral = "New Referral",
  Confirmed = "Confirmed",
  AdditionalProcedure = "Additional Procedure",
  PendingEligibility = "Pending Eligibility",
  PendingAuth = "Pending Auth",
  NoAuthRequired = "No Auth Required",
  Authorized = "Authorized",
  PendingConf = "Pending Conf",
  Scheduled = "Scheduled",
  PreProcedure = "PreProcedure",
  Procedure = "Procedure",
  PostProcedure = "PostProcedure",
  Discharge = "Discharge",
  Rescheduled = "Rescheduled",
  OpenProcedures = "Open Procedures",
  RetroAuth = "Retro Auth",
  Completed = "Completed",
  Cancelled = "Cancelled",
  ReadyForBilling = "Ready for Billing",
}

export enum CheckboxState {
  Checked = 1,
  Indeterminate,
  Unchecked,
}

export enum VisitType {
  HomeVisit = "Home Visit",
  OfficeVisit = "Office Visit",
  GroupHomeVisit = "Group Home Visit",
  TeleHealth = "Telehealth",
}

export enum DurationList {
  FifteenMins = 15,
  HalfHour = 30,
  FourtyFiveMins = 45,
  OneHour = 60,
}

export enum Region {
  Carotid = "Carotid",
  UpperExtremity = "Upper Extremity",
  Biliary = "Biliary",
  Renal = "Renal",
  LowerExtremity = "Lower Extremity",
}

export enum Area {
  Artery = "Artery",
  Vein = "Vein",
}

export enum Side {
  Left = "Left",
  Right = "Right",
}

export enum Location {
  DistalAbdominal = "Distal Abdominal",
  CommonIliac = "Common Iliac",
  ExternalIliac = "External Iliac",
  InternalIliac = "Internal Iliac",
  DeepFemoralProfunda = "Deep Femoral (Profunda)",
  CommonFemoral = "Common Femoral",
  ProxSuperficialFemoral = "Prox-Superficial Femoral",
  MidSuperficialFemoral = "Mid-Superficial Femoral",
  DisSuperficialFemoral = "Dis-Superficial Femoral",
  Popliteal = "Popliteal",
  AnteriorTibial = "Anterior Tibial",
  TibialPeronealTrunk = "Tibial Peroneal Trunk",
  PosteriorTibial = "Posterior Tibial",
  Peroneal = "Peroneal",
  DorsalisPedis = "Dorsalis Pedis",
  Planter = "Planter",
  Retroduodenal = "Retroduodenal",
  Gastroduodenal = "Gastroduodenal",
  RightBranchHepatic = "Right branch of the hepatic",
  Cystic = "Cystic",
  CeliacTrunk = "Celiac trunk",
  SuperiorMesenteric = "Superior Mesenteric",
  InferiorMesenteric = "Inferior mesenteric",
  Radial = "Radial",
  Ulnar = "Ulnar",
  Brachial = "Brachial",
  Brachialcephalic = "Brachialcephalic",
  Subclavian = "Subclavian",
}

export enum ProcedureType {
  Intervention = "Intervention",
  Diagnostic = "Diagnostic",
}

export enum CompReflex {
  Compression = "Compression",
  Reflex = "Reflex",
}

export enum NegativePositive {
  Negative = "Negative",
  Positive = "Positive",
}

export enum LabTest {
  BloodTest = "Blood Test",
  UrineTest = "Urine Test",
}

export enum DocStatus {
  Draft = "Draft",
  Submitted = "Submitted",
  Signed = "Signed",
}

export enum DocFilterType {
  ProcedureDoc = 0,
  OtherDoc,
}

export enum UploadDocumentType {
  Authorization = "Authorization",
  InsuranceInfo = "Insurance Info",
  Identification = "Identification",
  PreviousLabResults = "Previous lab results",
  IdentificationAndInsurance = "Identification & Insurance",
  SecondaryInsurance = "Secondary Insurance",
  OtherMiscellaneous = "Other/Miscellaneous",
  EKG = "EKG",
  PreviousPA = "Previous PA",
  ProblemList = "Problem List",
  MedicationList = "Medication list",
  Vitals = "Vitals",
  PayerNotice = "Payer Notice",
  DiagnosisNotes = "Diagnosis Notes",
  InfoReq = "Info Req",
  Diagnosis = "Diagnosis",
  LastEncounter = "Last Encounter",
  LastProcedure = "Last Procedure",
  Labs = "Labs",
  InformedConsent = "Informed Consent",
  UltrasoundFindings = "Ultrasound Findings",
  NonInvasive = "Non-Invasive ABI",
  Referral = "Referral",
}

export enum CptCodeType {
  Carotid = 1,
  Artery,
  Vein,
}

export enum CptCodeSection {
  Diagnostics = 1,
  Interventions,
}

export enum ChecklistMedicationType {
  BloodThinning = 1,
  Hypertension,
  Hyperlipidemia,
  Diabetes,
  Cilostazol,
}

export enum ReferralChecklistType {
  RutherfordClassification = 1,
  CeapScore,
  CessationProgram,
  WalkingProgram,
  Cilostazol,
  BloodThinningMedication,
  HypertensionMedications,
  HyperlipidemiaMedications,
  DiabetesMedications,
}

export enum ScheduledEventType {
  CheckIn = 1,
  Procedure,
  CheckOut,
}

export enum MedicationHoldStatus {
  OneDay = 1,
  TwoDays = 2,
  ThreeDays = 3,
  FourDays = 4,
  FiveDays = 5,
}

export enum ProgressbarStepper {
  PreProcedure,
  Procedure,
  PostProcedure,
  Discharge,
}

export enum ApiStatus {
  Pending = "Pending",
  Success = "Success",
  Failed = "Failed",
}

export enum ReferralApis {
  Patient = "patient",
  Documents = "documents",
  PriorAuth = "priorAuth",
  Coverages = "coverages",
  ChecklistFlags = "checklistFlags",
  ChecklistQuestions = "checklistQuestions",
  ChecklistValues = "checklistValues",
  Labs = "labs",
  ChiefComplaint = "chiefComplaint",
  Activity = "activity",
  Allergies = "allergies",
  InitiatePreAnesthsiaSave = "initiatePreAnesthsiaSave",
  PreAnesthsiaSaveCompleted = "preAnesthsiaSaveCompleted",
}

export enum ProcedureRoomApis {
  Patient = "patient",
  NeurovascularAssessment = "neurovascularAssessment",
  SnacksAndMeals = "snacksAndMeals",
  Vitals = "vitals",
  BloodGlucose = "bloodGlucose",
  DispenseSupplies = "dispenseSupplies",
  ModifiedAldreteScoring = "modifiedAldreteScoring",
  LeadEKG = "leadEKG",
  MedicationReconciliation = "medicationReconciliation",
  PreProcedureAssessment = "preProcedureAssessment",
  AdministerMedication = "administerMedication",
}

export enum OrderType {
  prior = "Prior",
  procedure = "Procedure",
  rx = "Rx",
}

