import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import {
  Patient,
  PatientImage,
  PatientSearchResult,
  UpsertPatient,
} from "../models/patient.model";
import { catchError, Observable, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiStatus, ReferralApis, Utils } from "./utils";
import { ApplicationCacheService } from "./application-cache.service";
import { cloneDeep } from "lodash";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class PatientService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _toastService: ToastrService,
    private readonly _applicationCacheService: ApplicationCacheService
  ) {}

  getCompanyPatients(): Observable<Patient[]> {
    const url = this.apiBaseUrl + "api/v1/patients";
    return this._dataService.get(url);
  }

  getPatientById(patientId: string): Observable<Patient> {
    if (this.isPatientAlreadyLoaded) {
      return of(this.patientCache);
    }
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.get(url).pipe(
      catchError((error) => {
        this._toastService.error(
          error || "Unable to get Patient details due to unknown reason.",
          "Error!"
        );
        this._applicationCacheService.setApiStatus(
          ApiStatus.Failed,
          ReferralApis.Patient
        );
        return of(error);
      }),
      tap((patientResponse: Patient) => {
        const patient = {
          ...patientResponse,
          gender: Utils.toTitleCase(patientResponse.gender),
          ethnicity: Utils.toTitleCase(patientResponse.ethnicity),
        };
        this._applicationCacheService.applicationCache.patient = patient;
        this._applicationCacheService.setApiStatus(
          ApiStatus.Success,
          ReferralApis.Patient
        );
      })
    );
  }

  insertPatient(patient: Partial<Patient>): Observable<Patient> {
    const url = this.apiBaseUrl + "api/v1/patients";
    return this._dataService.post(url, patient);
  }

  updatPatient(patientId: string, patient: UpsertPatient): Observable<Patient> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.patch(url, patient);
  }

  deletePatient(patientId: string): Observable<void> {
    const url = this.apiBaseUrl + `api/v1/patients/${patientId}`;
    return this._dataService.delete(url);
  }

  get apiBaseUrl(): string {
    return environment.apiBaseUrl;
  }

  getPatientImage(patientImage: PatientImage) {
    if (!patientImage) {
      return "/assets/images/custom/patient-avatar.png";
    }
    return `data:${patientImage.contentType};base64, ${patientImage.base64String}`;
  }

  searchPatientByPartialName(
    searchTerm: string
  ): Observable<PatientSearchResult[]> {
    if (!searchTerm) {
      return of(null);
    }
    const url = `${
      this.apiBaseUrl
    }api/v1/patients/search/term?term=${encodeURIComponent(searchTerm)}`;
    return this._dataService.get(url);
  }

  private get isPatientAlreadyLoaded(): boolean {
    return (
      this._applicationCacheService.getApiStatus(ReferralApis.Patient) ===
      ApiStatus.Success
    );
  }

  get patientCache(): Patient {
    return cloneDeep(this._applicationCacheService.applicationCache.patient);
  }
}
