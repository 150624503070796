import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import {
  FileUploadControl,
  FileUploadValidators,
} from "@iplab/ngx-file-upload";
import { DateTime } from "luxon";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { EnumerationItem } from "src/app/core/models/lookup.model";
import {
  PatientDoc,
  UploadPatientDoc,
} from "src/app/core/models/patient-docs.model";
import { ApplicationCacheService } from "src/app/core/services/application-cache.service";
import { PatientDocsService } from "src/app/core/services/patient-docs.service";
import { UploadDocumentType, Utils } from "src/app/core/services/utils";

@Component({
  selector: "app-upload-document-modal",
  templateUrl: "./upload-document-modal.component.html",
  styleUrls: ["./upload-document-modal.component.scss"],
})
export class UploadDocumentModalComponent {
  @ViewChild("patientDocForm") patientDocForm: NgForm | undefined = undefined;
  documentType: UploadDocumentType | undefined; //passed as modal param
  docNameLabel: string = "Name"; //passed as modal param
  disableName: boolean; //passed as modal param
  disableDocType: boolean = true; //passed as modal param
  showDocType: boolean = false; //passed as modal param
  showDocumentDate: boolean = false; //passed as modal param
  showPhysicalExamNote: boolean = false; //passed as modal param
  documentDate: Date;
  isSaving: boolean;
  newDoc: UploadPatientDoc;
  documentTypes: EnumerationItem[] = [];
  public readonly uploadedFile: BehaviorSubject<string> = new BehaviorSubject(
    null
  );
  private subscription: Subscription;
  public readonly control = new FileUploadControl(
    {
      listVisible: true,
      discardInvalid: true,
      multiple: false,
    },
    [FileUploadValidators.filesLimit(1)]
  );
  onClose: Subject<PatientDoc>;
  constructor(
    public bsModalRef: BsModalRef,
    private _toastService: ToastrService,
    private _applicationCacheService: ApplicationCacheService,
    private readonly _documentService: PatientDocsService
  ) {
    this.documentTypes = Utils.getEnumerationItemsFromUploadDocumentTypeEnum();
    this.onClose = new Subject();
  }

  public ngOnInit(): void {
    this.newDoc = new UploadPatientDoc(
      this.selectedPatientId,
      this.selectedEncounterId,
      this.selectedProcedureId,
      this.documentType
    );
    if (this.disableName) {
      this.newDoc.name = this.documentType;
    }
    this.subscription = this.control.valueChanges.subscribe(
      (values: Array<File>) => this.getImage(values[0])
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getImage(file: File): void {
    if (FileReader && file) {
      const fr = new FileReader();
      fr.onload = (e) => {
        const base64Splitted = (e.target as any).result.split("base64,");
        this.newDoc.docData.base64String = base64Splitted[1];
        this.newDoc.docData.contentType = base64Splitted[0]
          .replace(";", "")
          .replace("data:", "");
        this.uploadedFile.next((e.target as any).result);
      };
      fr.readAsDataURL(file);
    } else {
      this.uploadedFile.next(undefined);
    }
  }

  parseDate(date: string) {
    if (!date) {
      this.documentDate = null;
      return;
    }
    this.documentDate = new Date(date);
  }

  saveDocument(): void {
    if (!this.patientDocForm) {
      return;
    }
    this.patientDocForm.form.markAllAsTouched();
    if (this.patientDocForm?.invalid) {
      return;
    }
    if (
      !this.newDoc.docData?.base64String ||
      !this.newDoc.docData?.contentType
    ) {
      return;
    }
    this.isSaving = true;
    const payLoad = { ...this.newDoc };

    const formattedDate = this.showDocumentDate
      ? DateTime.fromJSDate(this.documentDate).toFormat("MM/dd/yyyy")
      : "";

    payLoad.name = this.showDocumentDate
      ? `${payLoad.name} ${formattedDate}`
      : payLoad.name;
    this._documentService
      .savePatientDoc(payLoad)
      .subscribe({
        next: (response: PatientDoc) => {
          this.onClose.next(response);
          this.bsModalRef.hide();
        },
        error: (err) => {
          this._toastService.error(
            err || "Unable to save Patient doc due to unknown reason.",
            "Error!"
          );
        },
      })
      .add(() => (this.isSaving = false));
  }

  get selectedPatientId(): string {
    return this._applicationCacheService.applicationCache.patient.id;
  }

  get selectedProcedureId(): string {
    return this._applicationCacheService.applicationCache.appointment
      .encounterProcedure.id;
  }
  get selectedEncounterId(): string {
    return this._applicationCacheService.applicationCache.appointment.id;
  }
}
