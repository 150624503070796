import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { UploadDocumentModalComponent } from "./upload-document-modal/upload-document-modal.component";
import { DocumentPreviewComponent } from "./document-preview/document-preview.component";
import { PatientDocsService } from "src/app/core/services/patient-docs.service";
import { ToastrService } from "ngx-toastr";
import { PatientDoc } from "src/app/core/models/patient-docs.model";
import Swal from "sweetalert2";
import { UploadDocumentType } from "src/app/core/services/utils";
import { ApplicationCacheService } from "src/app/core/services/application-cache.service";

@Component({
  selector: "app-document-attachment",
  templateUrl: "./document-attachment.component.html",
  styleUrls: ["./document-attachment.component.scss"],
})
export class DocumentAttachmentComponent {
  @Input() documentType: UploadDocumentType;
  @Input() docNameLabel: string = "Name";
  @Input() patientDoc: PatientDoc;
  @Input() suggestedText: string = "";
  @Input() disableName: boolean = false;
  @Input() disableDocType: boolean = false;
  @Input() showDocType: boolean = false;
  @Input() showDocumentDate: boolean = false;
  @Input() showPhysicalExamNote: boolean = false;

  @Output() onDocumentUploaded = new EventEmitter<PatientDoc>();
  @Output() onDocumentRemoved = new EventEmitter<PatientDoc>();
  isRemoving: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly _toastService: ToastrService,
    private readonly _documentService: PatientDocsService,
    private readonly _applicationCacheService: ApplicationCacheService,
    private readonly _modalService: BsModalService
  ) {}

  openAttachmentModal() {
    const options: ModalOptions = {
      class: "modal-md child-modal",
      initialState: {
        documentType: this.documentType,
        disableName: this.disableName,
        disableDocType: this.disableDocType,
        showDocType: this.showDocType,
        showDocumentDate: this.showDocumentDate,
        showPhysicalExamNote: this.showPhysicalExamNote,
        docNameLabel: this.docNameLabel,
      },
      ignoreBackdropClick: true,
    };
    const bsModalRef = this._modalService.show(
      UploadDocumentModalComponent,
      options
    );
    bsModalRef.content.onClose.subscribe((newlyCreatedDoc: PatientDoc) => {
      this._applicationCacheService.addDocumentToCache(newlyCreatedDoc);
      this.onDocumentUploaded.emit(newlyCreatedDoc);
    });
  }

  openDocumentViewModal() {
    const options: ModalOptions = {
      initialState: {
        signedLink: this.patientDoc.fileLink,
      },
      class: "modal-xl child-modal modal-dialog-scrollable",
      ignoreBackdropClick: true,
    };
    this._modalService.show(DocumentPreviewComponent, options);
  }

  removePatientDoc() {
    Swal.fire({
      title: "Delete Patient Document?",
      text: "Patient document will be removed, Proceed?.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#513DCA",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete",
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }
      this.isRemoving = true;
      this._documentService.deletePatientDoc(this.patientDoc.id).subscribe({
        next: () => {
          this._applicationCacheService.removePatientDocFromCache(
            this.patientDoc.id
          );
          this.onDocumentRemoved.emit(this.patientDoc);
        },
        error: (err) => {
          this._toastService.error(
            err || "Unable to delete document due to unknown reason.",
            "Error!"
          );
        },
        complete: () => {
          this.isRemoving = false;
        },
      });
    });
  }
}
